import React, { useState, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom';

const banner = {
    animate: {
        transition: {
            delayChildren: 0.4,
            staggerChildren: 0.1,
        },
    },
};

const letterAni = {
    initial: { y: 400 },
    animate: {
        y: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.95],
            duration: 1,
        },
    },
};

function Contact() {

    const [countryNameMap, setCountryNameMap] = useState([]);
    const [CountryPname, sethandleCountryPname] = useState('');

    const handleCountryPname = (event) => {
        sethandleCountryPname(event.target.value);
    }

    // validation start
    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, seContactNumber] = useState('');
    const [notes, setNotes] = useState('')
    const [submitErrorMessage, setSubmitErrorMessage] = useState('')
    const [successEmailStatus, setSuccessEmailStatus] = useState(false)

    const [errorList, setErrorList] = useState([]);

    const [countryName, setCountryName] = useState("")

    const [selectedSupport, setSelectedSupport] = useState('VIC');

    const supportChange = (event) => {
        const value = event.target.value;
        console.log("Selected support:", value);
        setSelectedSupport(value);
    };

    const [selectedMarket, setSelectedMarket] = useState('Europe');
    const [otherMarket, setOtherMarket] = useState('');

    const marketChange = (event) => {
        setSelectedMarket(event.target.value);
    };

    const otherMarketChange = (event) => {
        setOtherMarket(event.target.value);
    };

    const [showModal, setShowModal] = useState(true);
    const location = useLocation();

    // Function to handle modal toggle
    const handleModalToggle = () => {
        setShowModal(true);
    };

    // Listen for location changes to show modal
    useEffect(() => {
        // Check if we came from clicking the "Let's work together" link
        if (location.state?.fromWorkTogether) {
            handleModalToggle();
        }
    }, [location]);

    useEffect(() => {
        fetch('https://www.discoverymaldives.com/experience/get-country-all.php', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => response.json())
            .then(data => {
                setCountryNameMap(data)
                sethandleCountryPname(data[0].fldCountryName)
            }).catch((error) => {
                console.log('not work');
                console.log(error);
            });
    }, [])

    useEffect(() => {
        if (countryNameMap.length > 0) {
            clientCountry();
        }
    }, [countryNameMap]);

    const clientCountry = async () => {
        try {
            const ipResponse = await fetch("https://ipapi.co/json/", { mode: "cors" });
            const ipData = await ipResponse.json();

            if (ipData.error) {
                const clientIp = await fetch("https://api.ipify.org/?format=json"); //clientIpJson.ip
                const clientIpJson = await clientIp.json();
                const clientSecondIpDetails = await fetch("https://freeipapi.com/api/json/" + clientIpJson.ip); // correct one for now
                // const clientSecondIpDetails = await fetch("https://api.findip.net/"+clientIpJson.ip+"/?token=3af5074e3fb2460c8a2fb61df166c490");

                const clientSecondIpDetailsResult = await clientSecondIpDetails.json();
                const countryCode = await clientSecondIpDetailsResult.countryCode
                const countryFound = countryNameMap.find(item => item.fldCountry2Name === countryCode)
                if (countryFound) {
                    setCountryName(countryFound.fldCountryName);
                }



            } else {
                const countryCode = await ipData.country
                const countryFound = countryNameMap.find(item => item.fldCountry2Name === countryCode)
                if (countryFound) {
                    setCountryName(countryFound.fldCountryName);
                }

            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fullNameHandler = (event) => {
        setFullName(event.target.value);
    }
    const companyNameHandler = (event) => {
        setCompanyName(event.target.value);
    }

    const emailChange = (event) => {
        setEmail(event.target.value);
    }

    const contactNumberChange = (event) => {
        seContactNumber(event.target.value);
    }

    const notesHandler = (event) => {
        setNotes(event.target.value)
    }

    const [isActive, setActive] = useState(false);

    const formSubmitHandler = (event) => {

        if (successEmailStatus) {
            setSubmitErrorMessage("Thank you for registering with us. Your information has been received. We'll review it and get back to you shortly. Appreciate your patience and interest in our services!")
            return true;
        }

        setActive(true)
        event.preventDefault();

        let return_status = true;
        let errorMessages = []

        if (fullName == "") {
            errorMessages.fullName = `Full Name Required`;
            return_status = false;
            setActive(false)
        }

        if (companyName == "") {
            errorMessages.companyName = `Travel Agency / Tour Operator Name Name Required`;
            return_status = false;
            setActive(false)
        }


        if (email == "") {
            errorMessages.email = `Valid Email Address Required`;
            return_status = false;
            setActive(false)
        } else {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(email)) {
                errorMessages.email = `Please enter valid email Address`;
                return_status = false;
                setActive(false)
            }
        }

        if (contactNumber == "") {
            errorMessages.contactNumber = `Contact Number required`;
            return_status = false;
            setActive(false)
        }


        setErrorList(errorMessages);

        if (return_status) { //return_status
            window.lintrk('track', { conversion_id: 15757148 });
            fetch('https://www.viluxurholidays.com/thank-you-vh.php', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    formTitle: "Viluxur Form",
                    fullName: fullName,
                    companyName: companyName,
                    email: email,
                    contactNumber: contactNumber,
                    country: countryName,
                    preferredSupport: selectedSupport,
                    primaryMarket: selectedMarket === 'Other' ? `Other: ${otherMarket}` : selectedMarket,
                    notes: notes
                })
            }).then((response) => response.json())
                .then(data => {
                    if (data.code == 200) {
                        setSuccessEmailStatus(true)
                        setSubmitErrorMessage("Thank you for registering. We've received your information and will be in touch soon. Appreciate your interest!")
                        // window.location.href = 'https://www.ovholidays.com/thankyou.php';
                    } else {
                        // window.location.href = 'https://www.ovholidays.com/errorovx.php';
                        setSuccessEmailStatus(false)
                        setSubmitErrorMessage("We regret to inform you that there was an issue processing your registration. Our team is working to resolve it promptly. If you have any immediate concerns, please contact our support team at info@viluxurholidays.com. We appreciate your patience and apologize for any inconvenience caused. Thank you for your understanding.")
                    }
                }).catch((error) => {
                    console.log('not work');
                    console.log(error);
                });
        }
    }
    // validation end
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return (
        <div>
            <Header onWorkTogetherClick={handleModalToggle} />
            {isDesktopOrLaptop &&
                <div className="page-top-space we-are-future">
                    {/* <section className="container">
                        <div className="row margin-top-and-botm" style={{ justifyContent: "center" }}>
                            <div className="col-12">
                                <div className=" ">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + `/images/VH-Web-Banner.jpg`} alt="contact-us-banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="model_section">
                        <div className={`modal fade ${showModal ? 'show' : ''}`}
                            style={{ display: showModal ? 'block' : 'none' }}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="formModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button"
                                            className="close"
                                            onClick={() => setShowModal(false)}
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <h2 className=" modal-title title-2 text-center">Get in Touch with Us</h2>
                                        <p className="para text-center">
                                            Need a perfect getaway? Viluxur Holidays is here to help! Contact us via form, email, or phone to start planning your dream vacation. Our travel experts can't wait to craft your next unforgettable adventure.</p>
                                        <form className="">
                                            <div className="row input-style">
                                                <div className="col-6">
                                                    <p className="para-form">Full Name *</p>
                                                    <input type="text" autoComplete="off" name="fldCustomerFName" onChange={fullNameHandler} />
                                                    <div className="error-waper">
                                                        <div className="erroe-msg">{errorList.fullName}</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <p className="para-form">Travel Agency / Tour Operator Name *</p>
                                                    <input type="text" autoComplete="off" name="fldCustomerLName" onChange={companyNameHandler} />
                                                    <div className="error-waper">
                                                        <div className="erroe-msg">{errorList.companyName}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row input-style">
                                                <div className="col-6">
                                                    <p className="para-form">Country Of Origin</p>
                                                    <input type="text" name="fldCustomerFormResort1ID" onChange={handleCountryPname} value={countryName} readOnly />
                                                </div>
                                                <div className="col-6">
                                                    <p className="para-form">Email *</p>
                                                    <input type="text" name="fldCustomerEmail" onChange={emailChange} />
                                                    <div className="error-waper">
                                                        <div className="erroe-msg">{errorList.email}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row input-style">
                                                <div className="col-6">
                                                    <p className="para-form">Contact Number *</p>
                                                    <input type="number" name="fldCustomerPNumber" onChange={contactNumberChange} />
                                                    <div className="error-waper">
                                                        <div className="erroe-msg">{errorList.contactNumber}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row input-style">
                                                <div className="col-6">
                                                    <p className="para-form">Preferred Support *</p>
                                                    <small className="form-small">Please select one</small>
                                                    <div className="support-options">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerSupport"
                                                                value="VIC"
                                                                checked={selectedSupport === 'VIC'}
                                                                onChange={supportChange}
                                                            />
                                                            I would like access to VIC <br />(Viluxur Inventory Connect)
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerSupport"
                                                                value="SALES-AGENT"
                                                                checked={selectedSupport === 'SALES-AGENT'}
                                                                onChange={supportChange}
                                                            />
                                                            I would like to connect with a <br /> Viluxur Holidays Sales Agent
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <p className="para-form">Primary Market *</p>
                                                    <small className="form-small">(Please select the market you primarily serve)</small>
                                                    <div className="support-options">
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerMarket"
                                                                value="Europe"
                                                                checked={selectedMarket === 'Europe'}
                                                                onChange={marketChange}
                                                            />
                                                            Europe
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerMarket"
                                                                value="Middle East"
                                                                checked={selectedMarket === 'Middle East'}
                                                                onChange={marketChange}
                                                            />
                                                            Middle East
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerMarket"
                                                                value="Asia"
                                                                checked={selectedMarket === 'Asia'}
                                                                onChange={marketChange}
                                                            />
                                                            Asia
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerMarket"
                                                                value="Russia (CIS)"
                                                                checked={selectedMarket === 'Russia (CIS)'}
                                                                onChange={marketChange}
                                                            />
                                                            Russia (CIS)
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name="fldCustomerMarket"
                                                                value="Other"
                                                                checked={selectedMarket === 'Other'}
                                                                onChange={marketChange}
                                                            />
                                                            Other
                                                        </label>
                                                        {selectedMarket === 'Other' && (
                                                            <div className="other-market-input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Please specify"
                                                                    value={otherMarket}
                                                                    onChange={otherMarketChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row input-style">
                                                <div className="col-12">
                                                    <p className="para-form mb-20">Notes</p>
                                                    <textarea name="notes" onChange={notesHandler}></textarea>
                                                </div>
                                            </div>

                                            <div className="row input-style mt-50">
                                                <button className="submit-btn" type="button" onClick={formSubmitHandler}>Submit</button>
                                                <div className="submit-message-wrapper mt-5">
                                                    <div className="submit-message">
                                                        <h4>{submitErrorMessage}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showModal && <div className="modal-backdrop fade show"></div>}
                    </section>
                </div>
            }

            {isTabletOrMobile &&
                <div className="page-space-top-mobile team-mobile rebuild">
                    <motion.div
                        initial={{ opacity: 0, y: 180 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            ease: "easeInOut",
                            duration: 1,
                            delay: 0.6,
                        }}
                        className=""
                    >
                        <section className="container mt-8 mb-3">
                            <div className="row">
                                <div className="col-12 bg-white pdn-30-form">
                                    <h2 className="title-2 text-center">Are Your Customers Looking For Purposeful Travel To The Maldives?</h2>
                                    <p className="para text-center">Allow Viluxur to curate the best packages with our resort partners that will not only teach them about the endangered species found in the Maldives but also allow them to partake in the change being made.
                                    </p>
                                    <form className="mt-5-form">
                                        <div className="row input-style">
                                            <div className="col-12">
                                                <p className="para-form"> Full Name *</p>
                                                <input type="text" autoComplete="off" name="fldCustomerFName" onChange={fullNameHandler} />
                                                <div className="error-waper">
                                                    <div className="erroe-msg">{errorList.fullName}</div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <p className="para-form"> Travel Agency / Tour Operator Name *</p>
                                                <input type="text" autoComplete="off" name="fldCustomerLName" onChange={companyNameHandler} />
                                                <div className="error-waper">
                                                    <div className="erroe-msg">{errorList.companyName}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row input-style">
                                            <div className="col-12">
                                                <p className="para-form"> Country Of Origin </p>
                                                <input type="text" autoComplete="off" name="fldCustomerFormResort1ID" onChange={handleCountryPname} value={countryName} readOnly />
                                            </div>
                                            <div className="col-12">
                                                <p className="para-form"> Email *</p>
                                                <input type="text" name="fldCustomerEmail" onChange={emailChange} />
                                                <div className="error-waper">
                                                    <div className="erroe-msg">{errorList.email}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row input-style">
                                            <div className="col-12">
                                                <p className="para-form"> Contact Number *</p>
                                                <input type="text" name="fldCustomerPNumber" onChange={contactNumberChange} />
                                                <div className="error-waper">
                                                    <div className="erroe-msg">{errorList.contactNumber}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row input-style">
                                            <div className="col-12">
                                                <p className="para-form">Preferred Support *</p>
                                                <small className="form-small">Please select one</small>
                                                <div className="support-options">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerSupport"
                                                            value="VIC"
                                                            checked={selectedSupport === 'VIC'}
                                                            onChange={supportChange}
                                                        />
                                                        I would like access to VIC <br />(Viluxur Inventory Connect)
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerSupport"
                                                            value="SALES-AGENT"
                                                            checked={selectedSupport === 'SALES-AGENT'}
                                                            onChange={supportChange}
                                                        />
                                                        I would like to connect with a <br /> Viluxur Holidays Sales Agent
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <p className="para-form">Primary Market *</p>
                                                <small className="form-small">(Please select the market you primarily serve)</small>
                                                <div className="support-options">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerMarket"
                                                            value="Europe"
                                                            checked={selectedMarket === 'Europe'}
                                                            onChange={marketChange}
                                                        />
                                                        Europe
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerMarket"
                                                            value="Middle East"
                                                            checked={selectedMarket === 'Middle East'}
                                                            onChange={marketChange}
                                                        />
                                                        Middle East
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerMarket"
                                                            value="Asia"
                                                            checked={selectedMarket === 'Asia'}
                                                            onChange={marketChange}
                                                        />
                                                        Asia
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerMarket"
                                                            value="Russia (CIS)"
                                                            checked={selectedMarket === 'Russia (CIS)'}
                                                            onChange={marketChange}
                                                        />
                                                        Russia (CIS)
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="fldCustomerMarket"
                                                            value="Other"
                                                            checked={selectedMarket === 'Other'}
                                                            onChange={marketChange}
                                                        />
                                                        Other
                                                    </label>
                                                    {selectedMarket === 'Other' && (
                                                        <div className="other-market-input" style={{ marginLeft: '30px', marginTop: '10px' }}>
                                                            <input
                                                                type="text"
                                                                placeholder="Please specify"
                                                                value={otherMarket}
                                                                onChange={otherMarketChange}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row input-style">
                                            <div className="col-12">
                                                <p className="para-form mb-20">Notes</p>
                                                <textarea type="text" name="fldCustomerEmail" onChange={notesHandler}></textarea>
                                            </div>
                                        </div>
                                        <div className="row input-style mt-50">
                                            <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
                                            <div className="submit-message-wrapper">
                                                <div className="submit-message">
                                                    <h5>{submitErrorMessage}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </motion.div>
                </div>
            }
            <Footer />
        </div>
    );
}

const AnimatedLetters = ({ title, disabled }) => (
    <motion.span
        className="row-title"
        variants={disabled ? null : banner}
        initial="initial"
        animate="animate">
        {[...title].map((letter) => (
            <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
        ))}
    </motion.span>
);


const AnimatedLetters2 = ({ title, disabled }) => (
    <motion.span
        className="row-title-gray row-title"
        variants={disabled ? null : banner}
        initial="initial"
        animate="animate">
        {[...title].map((letter) => (
            <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
        ))}
    </motion.span>
);

export default Contact;